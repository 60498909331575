/* App.css */
.App {
    display: flex;
    flex-direction: column;
    min-height: 50vh;
  }
  
  .App > * {
    flex: 1 0 auto;
  }
  
  footer {
    flex-shrink: 0;
  }
  