*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}


@font-face {
  font-family: 'Century Gothic';
  src: url('./fonts/centurygothic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Century Gothic';
  src: url('./fonts/centurygothic_bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}



body {

  margin: 0;
  
   
      font-family:'Century Gothic', sans-serif !important;
    

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


body, html {
  margin: 0;
  padding: 0;
  /* height: 100%;
   */
   min-height: 100vh;
    scroll-behavior: smooth;
  
  
  overflow-x: auto;
  overflow-y: auto;  /* Allow vertical scrolling, but hide the scrollbar */
}

/* Hide scrollbar on Webkit browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  display: none;
}


@keyframes moveBackground {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 100%;
  }
}
/* Modified Slide-in Animation */
@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
#trigger-section {
  min-height: 100vh; /* Adjust this */
}
